<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        Staking ID : <strong> {{ this.staking_number }}</strong>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="3">
            <h6>Initial Date</h6>
            <div class="mb-3"><strong>{{ this.created_at }}</strong></div>
          </b-col>
          <b-col sm="3">
            <h6>Initial Gram</h6>
            <div class="mb-3"><strong>{{ this.amount }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Status</h6>
            <div class="mb-3"><strong>{{ this.status }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Admin Fee</h6>
            <div class="mb-3"><strong>{{ this.admin_fee }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Total Pembayaran</h6>
            <div class="mb-3"><strong>{{ this.invoice_amount }}</strong></div>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col sm="3">
            <h6>User</h6>
            <div class="mb-3"><strong>{{ this.name }}  ( {{ this.email }} )</strong></div>
          </b-col>
          <b-col sm="3">
            <h6>Tenor</h6>
            <div class="mb-3"><strong>{{ this.period }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Metode Pembayaran</h6>
            <div class="mb-3"><strong>{{ this.payment_name }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Pajak</h6>
            <div class="mb-3"><strong>{{ this.tax }}</strong></div>
          </b-col>
          <b-col sm="2">
            <h6>Harga Beli Emas</h6>
            <div class="mb-3"><strong>{{ this.buying_rate }}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-header>
        History
      </b-card-header>
      <b-card-body>
        <div>
          <vuetable ref="vuetable"
            @vuetable:load-error="handleLoadError"
            :api-url="apiUrl"
            :http-options="HttpOptions"
            :fields="fields"
            pagination-path=""
            :muti-sort="true"
            :sort-order="sortOrder"
            :append-params="moreParams"
            @vuetable:pagination-data="onPaginationData">
              <template slot="name-slot" slot-scope="prop">
                <span>{{ prop.rowData.name }}</span>
              </template>
              <template slot="bank_name-slot" slot-scope="prop">
                <span>{{ prop.rowData.bank_name }} - {{ prop.rowData.account_number }}</span>
              </template>
              <template slot="amount-slot" slot-scope="prop">
                <span v-if="prop.rowData.bank_account.bank_id === 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id === 'SYNIIDJ1'">
                  {{ Number(prop.rowData.amount) | currency}}
                </span>
                <span v-else-if="prop.rowData.listing == false && prop.rowData.bank_account.bank_id !== 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id !== 'SYNIIDJ1'">
                  {{ (Number(Number(prop.rowData.amount) - 3000)) | currency}}
                </span>
                <span v-else>
                  {{ (Number(Number(prop.rowData.amount) - 3000)) | currency}}
                </span>
              </template>
            </vuetable>
          <div class="vuetable-pagination ui basic segment grid">
            <vuetable-pagination-info ref="paginationInfo"
            ></vuetable-pagination-info>

            <vuetable-pagination ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import axios from  'axios'

export default {
  name: 'detail-staking',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      id: '',
      staking_number: '',
      amount: '',
      created_at: '',
      status: '',
      period: '',
      admin_fee: '',
      invoice_amount: '',
      buying_rate: '',
      payment_name: '',
      tax: '',
      invoice_number: '',
      name: '',
      email: '',
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `staking-history?hashed=${btoa(this.$route.params.detail)}`,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: 'created_at',
          title: 'Date'
        },
        {
          name: 'invoice_number',
          title: 'Invoice Number'
        },
        {
          name: 'description',
          title: 'Description'
        },
        {
          name: 'amount',
          title: 'Gold Amount'
        },
        {
          name: 'buying_rate',
          title: 'Gold Buy Price'
        },
        {
          name: 'selling_rate',
          title: 'Gold Sell Price'
        }
      ],
      errors: {
          message: [],
          status: [],
          code: '',
          headers: [],
          form: {
              reason: [],
          },
      },
      isLoading: false
    }
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    this.$http.get(`staking/` + atob(this.$route.params.id))
      .then((result) => {
        let res = result.data.data;
        this.invoice_number = res.invoice_number
        this.staking_number = res.gold_staking.staking_number
        this.created_at = res.gold_staking.created_at
        this.amount = res.gold_staking.amount
        this.status = res.gold_staking.status
        this.invoice_amount = res.invoice.amount
        this.admin_fee = res.invoice.admin_fee
        this.payment_name = res.invoice.payment_name
        this.buying_rate = res.invoice.buying_rate
        this.name = res.users.name
        this.email = res.users.email
        this.tax = res.invoice.tax
        this.period = res.gold_staking_period.period_text
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })
  },
  methods: {
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
  }
}
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
